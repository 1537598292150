<template>
  <div class="view-home for-members-bg">
    <div class="container">
      <div class="row about-page">
        <div class="col-md-12">
          <div class="breadcrumb px-0">
            <router-link to="/">{{ $t('breadcrumb.main-page') }}</router-link>
            <router-link to="/for-members" class="active">{{ $t('trial-tests') }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="practice-test-wrapper">
      <div class="trial-img-right">
        <img src="/images/trial-test-right.png" height="100%" alt=""/>
      </div>
      <div class="trial-img-left">
        <img src="/images/trial-test-left.png" height="100%" alt=""/>
      </div>
      <div class="container">
        <div class="practice-test-desc">
          <div class="practice-test-desc-left">
            <img width="400" height="400" src="/images/trial-test-img.png" alt=""/>
          </div>
          <div class="practice-test-desc-right">
            <h2>
              {{$t('practice_tests_title')}}
            </h2>
            <p>
              {{$t('practice_tests_text_1')}}
            </p>
            <a class="practice-test-go-to" :href="$t('trial_test_link')">
              <img height="53" width="53" src="/images/trial-test-owl.png" alt=""/>
              <p v-html="$t('practice_tests_text_2')">

              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="practice-test-wrapper-mobile">
      <div class="practice-test-wrapper-mobile-img">
        <img src="/images/trail-test-back-mobile.png" width="100%" alt="">
      </div>
      <div>
        <img src="/images/trial-test-mobile-img.png" width="100%" alt="">
      </div>
      <p class="practice-test-wrapper-mobile-title">
        {{ $t('practice_tests_title') }}
      </p>
      <p class="practice-test-wrapper-mobile-subtitle">
        {{ $t('practice_tests_text_1') }}
      </p>
      <a class="practice-test-go-to" :href="$t('trial_test_link')">
        <img height="53" width="53" src="/images/trial-test-owl.png" alt=""/>
        <p v-html="$t('practice_tests_text_2')">

        </p>
      </a>
    </div>
    <div class="container">
      <div class="what-awaits">
        <div class="what-awaits-back-star">
          <img width="78" height="78" src="/images/star-full.png" alt="">
        </div>
        <div class="what-awaits-title">
          {{ $t('practice_tests_text_3') }}
        </div>
        <div class="what-awaits-stage-wrapper">
          <div class="what-awaits-stage">
            <div class="what-awaits-stage-title">
              <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.5 32C0.5 14.3269 14.8269 0 32.5 0C50.1731 0 64.5 14.3269 64.5 32C64.5 49.6731 50.1731 64 32.5 64C14.8269 64 0.5 49.6731 0.5 32Z"
                    fill="url(#paint0_linear_70_3516)"/>
                <path
                    d="M32.5 20L35.7411 28.7589L44.5 32L35.7411 35.2411L32.5 44L29.2589 35.2411L20.5 32L29.2589 28.7589L32.5 20Z"
                    fill="white"/>
                <defs>
                  <linearGradient id="paint0_linear_70_3516" x1="0.5" y1="32" x2="64.5" y2="32"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#407BFF"/>
                    <stop offset="0.08" stop-color="#306EE3"/>
                    <stop offset="0.59" stop-color="#1C5FC2"/>
                    <stop offset="1" stop-color="#1358B3"/>
                  </linearGradient>
                </defs>
              </svg>
              {{ $t('practice_tests_text_4') }}
            </div>
            <div class="tests-subjects-wrapper">
              <div class="tests-subjects-header">
                <p style="text-align: left">
                  {{ $t('practice_tests_text_5') }}
                </p>
                <p style="text-align: right">
                  {{ $t('practice_tests_text_6') }}
                </p>
              </div>
              <div class="tests-subjects-row">
                <p>
                  {{ $t('practice_tests_text_7') }}
                </p>
                <p>
                  {{ $t('practice_tests_text_9') }}
                </p>
              </div>
              <div class="tests-subjects-row">
                <p>
                  {{ $t('practice_tests_text_8') }}
                </p>
                <p>
                  {{ $t('practice_tests_text_9') }}
                </p>
              </div>
            </div>
            <a class="what-awaits-link" :href="$t('olympiad_mock_tests_url_1')">
              {{ $t('practice_tests_text_11') }}
            </a>
          </div>
          <div class="what-awaits-stage">
            <div class="what-awaits-stage-title">
              <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.5 32C0.5 14.3269 14.8269 0 32.5 0C50.1731 0 64.5 14.3269 64.5 32C64.5 49.6731 50.1731 64 32.5 64C14.8269 64 0.5 49.6731 0.5 32Z"
                    fill="url(#paint0_linear_70_3516)"/>
                <path
                    d="M32.5 20L35.7411 28.7589L44.5 32L35.7411 35.2411L32.5 44L29.2589 35.2411L20.5 32L29.2589 28.7589L32.5 20Z"
                    fill="white"/>
                <defs>
                  <linearGradient id="paint0_linear_70_3516" x1="0.5" y1="32" x2="64.5" y2="32"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#407BFF"/>
                    <stop offset="0.08" stop-color="#306EE3"/>
                    <stop offset="0.59" stop-color="#1C5FC2"/>
                    <stop offset="1" stop-color="#1358B3"/>
                  </linearGradient>
                </defs>
              </svg>
              {{ $t('practice_tests_text_10') }}
            </div>
            <div class="tests-subjects-wrapper">
              <div class="tests-subjects-header">
                <p style="text-align: left">
                  {{ $t('practice_tests_text_5') }}
                </p>
                <p style="text-align: right">
                  {{ $t('practice_tests_text_6') }}
                </p>
              </div>
              <div class="tests-subjects-row">
                <p>
                  {{ $t('practice_tests_subject_1') }}
                </p>
                <p>
                  {{ $t('practice_tests_text_18') }}
                </p>
              </div>
              <div class="tests-subjects-row">
                <p>
                  {{ $t('practice_tests_subject_2') }}
                </p>
                <p>
                  {{ $t('practice_tests_text_9') }}
                </p>
              </div>
              <div class="tests-subjects-row">
                <p>
                  {{ $t('practice_tests_subject_3') }}
                </p>
                <p>
                  {{ $t('practice_tests_text_9') }}
                </p>
              </div>
              <div class="tests-subjects-row">
                <p>
                  {{ $t('practice_tests_subject_4') }}
                </p>
                <p>
                  {{ $t('practice_tests_text_9') }}
                </p>
              </div>
            </div>
            <a class="what-awaits-link" :href="$t('olympiad_mock_tests_url_2')">
              {{ $t('practice_tests_text_11') }}
            </a>
          </div>
        </div>
      </div>

      <div class="trial-test-access-wrapper">
        <div class="trial-test-access-img">
          <img width="168" height="168" src="/images/test-access-img.png" alt=""/>
        </div>
        <div class="trial-test-access-block">
          <h2>
            {{ $t('practice_tests_text_12') }}
          </h2>
          <p>
            {{ $t('practice_tests_text_13') }}
          </p>
          <div class="practice-test-go-to-access">
            <p class="practice-test-go-to-text" v-html="$t('practice_tests_text_14')">

            </p>
            <img height="53" width="53" src="/images/trial-test-owl.png" alt=""/>
            <a class="practice-test-go-to-link" v-html="$t('practice_tests_text_15')" :href="$t('trial_test_link')">

            </a>
          </div>
        </div>
      </div>
      <div class="trial-test-access-wrapper-mobile">
        <div class="trial-test-access-mobile-img">
          <img width="192" height="192" src="/images/test-access-img.png" alt=""/>
        </div>
        <div class="trial-test-access-mobile-desc">
          <p class="trial-test-access-mobile-desc-title">
            {{ $t('practice_tests_text_12') }}
          </p>
          <p class="trial-test-access-mobile-desc-subtitle">
            {{ $t('practice_tests_text_13') }}
          </p>
        </div>
      </div>
    </div>
    <div class="ready-to-test">
      <div class="ready-to-test-back">
        <img src="/images/ready-test-back.png" width="100%" alt="">
      </div>
      <div class="ready-to-test-title" v-html="$t('practice_tests_text_14')">

      </div>
      <a class="ready-to-test-link" v-html="$t('practice_tests_text_15')" :href="$t('trial_test_link')">

      </a>
      <img height="53" width="53" src="/images/trial-test-owl.png" alt=""/>
    </div>
  </div>
</template>
<script>

export default {

  data() {
    return {

    }
  },
}
</script>
<style>

</style>
